import React from "react"
import Cursor from "../images/hourglass_01.svg"
import {getMousePosition} from "../utils/"

import "./popup.scss"

const scriptURL = 'https://4xya056cv6.execute-api.us-east-1.amazonaws.com/Production'

class Popup extends React.Component {

  constructor() {
    super();
    this.state = {
      isVisible: false,
      submitted: false,
      errorMessage: null,
      submitting: false,
      emailNotValid: false,
    };
    this.mouseMove = this.mouseMove.bind(this);
    this.onClickClose = this.onClickClose.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {

    window.addEventListener("mousemove", this.mouseMove);

    this.emailRef.addEventListener("blur", () => {
      this.setState({
        emailNotValid: !this.emailRef.checkValidity(),
      })
    });

    this.emailRef.addEventListener("focus", () => {
      this.setState({
        errorMessage: null,
        emailNotValid: false,
      })
    })
  }

  componentWillUnmount() {
    window.removeEventListener("mousemove", this.mouseMove);
  }

  mouseMove(e) {
    const mouse = getMousePosition(e);
    this.cursorRef.style.left = `${mouse.x-25}px`;
    this.cursorRef.style.top = `${mouse.y-25}px`;
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
        isVisible: nextProps.isVisible,
    })
  }

  onClickClose() {
    this.props.onClose();
  }

  onSubmit(e) {
    e.preventDefault();

    if(this.emailRef.value.length > 0 && this.nameRef.value.length > 0) {

      if(this.emailRef.checkValidity()) {

        this.setState({
          submitting: true,
        })
        
        console.log(this.nameRef.value, this.emailRef.value, !!this.guestsRef.value ? this.guestsRef.value : 0)
        fetch(scriptURL, { method: 'POST', body: JSON.stringify({
          full_name: this.nameRef.value,
          email: this.emailRef.value,
          guests: !!this.guestsRef.value ? this.guestsRef.value : 0,
        }),
          cache: 'no-cache',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
        })
        .then(response => response.json())
        .then(response => {
          console.log('Success!', response)
          this.setState({
            submitted: true,
            submitting: false,
          })
        })
        .catch(error => {
          console.error('Error!', error.message)
          this.setState({
            submitting: false,
          })
        })
        
      } else {
        this.setState({
          errorMessage: "invalid email address"
        })
      }

    } else {
      this.setState({
        errorMessage: "information missing"
      })
    }

  }

  render() {

    const { isVisible, submitted, errorMessage, submitting, emailNotValid } = this.state;

    return (
      <div className={`popup ${isVisible && 'popup--visible'}`}>
        <div className={`popup__content ${submitting ? 'popup__content--loading' : ''}`}>
            <div className="popup__close" onClick={this.onClickClose}></div>

            <div className={`popup__done ${submitted && "popup__done--visible"}`}>
              <div>
                <p className={"popup__content__title"}>Great!</p>
                <p>You're all set, see you there.</p>
              </div>
              <a href={"./FirstbornArtShow2019.ics"}>Add this not-to-be-missed event to my <span className={"underline"}>calendar.</span></a>
            </div>

            <div className={`popup__rsvp ${submitted && "popup__rsvp--hidden"}`}>
                <p className={"popup__content__title"}>rsvp:</p>

                <form ref={r=>{this.formRef=r}}>

                    <input ref={r=>{this.nameRef=r}} type="text" placeholder="Your full name*" />
                    <input ref={r=>{this.emailRef=r}} className={`${emailNotValid && 'invalid-input'}`} type="email" placeholder="Your email*" />
                    <label>
                        <span>Guests?</span>
                        <input ref={r=>{this.guestsRef=r}} type="number" max="5" min="0" placeholder={0}/>
                    </label>

                    <button onClick={this.onSubmit} type="submit">{submitting ? 'Submitting...' : 'Submit'}</button>
                    {errorMessage && <p className={"popup__error"}>{errorMessage}</p>}
                </form>
            </div>
        </div>
        <img className={"content__cursor__hourglass"} ref={r=>this.cursorRef=r} src={Cursor}
          style={{
            display: submitting ? 'block' : 'none',
          }}
        ></img>
      </div>
    )
  }
}
export default Popup
